import React from 'react';
import axios from 'axios';
import { DateTime } from 'luxon'
import './Games.css';


class Contact extends React.Component {
  state = {
    name: "",
    mobile: "",
    email: "",
    message: ""
  };
  contactSubmit = e => {
    e.preventDefault();
    const now = DateTime.now();
    const formattedDate = now.toFormat('dd-MM-yyyy HH:mm:ss')
    const contact = {
      name: this.state.name,
      mobile: this.state.mobile,
      email: this.state.email,
      message: this.state.message,
      time: formattedDate,
    };
    axios.post("/contacts.json", contact)
      .then(this.setState({
      name: '',
      mobile: '',
      email: '',
      message: ''
      }))
      .catch(error => console.log(error));
  };
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };
  render() {
    return (
      <div className="Games-page">
        <div className="side-bar" />
        <div className="Games-Container">
          <div className="contact-container" >
            <form onSubmit={this.contactSubmit} >
                <label htmlFor="icon_prefix">Name</label>
                  <input
                    id="name"
                    type="text"
                    onChange={this.handleChange} 
                    value={this.state.name} 
                    placeholder="Enter Your Name" 
                    className="contact-input" 
                    required
                  />              
                
                  <label htmlFor="icon_telephone">Mobile</label>
                  <input
                    id="mobile"
                    type="number"
                    onChange={this.handleChange} 
                    value={this.state.mobile}
                    placeholder="Enter Mobile NUmber" 
                    className="contact-input"
                    required
                  />            
                          
                
                <label htmlFor="icon_email">Email</label>
                  <input
                    id="email"
                    type="text"
                    onChange={this.handleChange} 
                    value={this.state.email} 
                    placeholder="Enter Email" 
                    className="contact-input" 
                    required
                  />                          
                           
                
                <label htmlFor="textarea1">Message</label>
                  <textarea
                    id="message"
                    type="text"
                    onChange={this.handleChange} 
                    value={this.state.message} 
                    placeholder="Type Your Message" 
                    className="contact-input"
                    required
                  />                 
                
                <button>Submit</button>
             
            </form>
          </div>
        </div>

        <div className="side-bar" />
      </div>
    );
  }
}
export default Contact;