import React from 'react';
import Result from './Result';
import NextLevel from './NextLevel';
import axios from 'axios';
import { DateTime } from 'luxon'
import './Guess.css';


class GuessLevelOne extends React.Component {
  state = {
    number: [],
    defaultText: '* *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 99) + 1,
    count: 8,
    keycode: '',
    level: 1,
    score: 0,
    status: '',
    lastNumber: 100,
    userName: '',
    rank: '',
    defaultUser: '',
    showToppers: false,
    toprankers: []
  }

  getToppers = () => {
   axios.get("/guess.json")
   .then(response => { 
     const fetchedResults = [];
     for(let key in response.data){
       fetchedResults.unshift(
         {
           ...response.data[key],
           id:key
         }
       )
     }
     this.setState({
       toprankers: fetchedResults.sort((a,b) => (b.score - a.score))
     });
   })
 }
  
  handleClick = (event) => {
    if(this.state.number.length <= this.state.level) {
    this.setState({
      defaultText: '',
      number: this.state.number.concat(event.target.id)
    });
    }
  }

  keyDown = (e) => {
    e.preventDefault();
    if(this.state.number.length <= this.state.level) {
    if(window.event.keyCode === 49) {
    this.setState({
      defaultText: '',
      number: this.state.number.concat(1),
      keycode: window.event.keyCode
       });
    }
    if(window.event.keyCode === 50) {
    this.setState({
      defaultText: '',
      number: this.state.number.concat(2),
      keycode: window.event.keyCode
       });
    }
    if(window.event.keyCode === 51) {
    this.setState({
      defaultText: '',
      number: this.state.number.concat(3),
      keycode: window.event.keyCode
       });
    }
    if(window.event.keyCode === 52) {
    this.setState({
      defaultText: '',
      number: this.state.number.concat(4),
      keycode: window.event.keyCode
       });
    }
    if(window.event.keyCode === 53) {
    this.setState({
      defaultText: '',
      number: this.state.number.concat(5),
      keycode: window.event.keyCode
       });
    }
    if(window.event.keyCode === 54) {
    this.setState({
      defaultText: '',
      number: this.state.number.concat(6),
      keycode: window.event.keyCode
       });
    }
    if(window.event.keyCode === 55) {
    this.setState({
      defaultText: '',
      number: this.state.number.concat(7),
      keycode: window.event.keyCode
       });
    }
    if(window.event.keyCode === 56) {
    this.setState({
      defaultText: '',
      number: this.state.number.concat(8),
      keycode: window.event.keyCode
       });
      }
    if(window.event.keyCode === 57) {
     
    this.setState({
      defaultText: '',
      number: this.state.number.concat(9),
      keycode: window.event.keyCode
       });
    
    }
    if(window.event.keyCode === 48) {
      
    this.setState({
      defaultText: '',
      number: this.state.number.concat(0),
      keycode: window.event.keyCode
       });
    
    }
    }
    if(window.event.keyCode === 46) {
      this.deleteNumber();
    }
    if(window.event.keyCode === 13) {
      this.submitAnswer();
    }  
    else {
      this.setState({
        keycode: window.event.keyCode
      });
    }  
  }
  
  deleteNumber = () => {
    const index = this.state.number.length - 1;
    const newNumber = this.state.number.slice();
    newNumber.splice(index,1)
    this.setState({
      number: newNumber,
      keycode: window.event.keyCode
    });
  }

  submitAnswer = () => {
    if(!this.state.number.length) {
    alert("Number Should not be Empty"); 
    }
    else {
    const newInstruction = this.state.instructions;
    
    if(this.state.number.join('') > this.state.secretNumber) {
      newInstruction.unshift("The Number is smaller than " + this.state.number.join(''));
    this.setState({
      instructions: newInstruction,
      number: [],
      count: this.state.count - 1,
      keycode: window.event.keyCode
    });
    }
    if(this.state.number.join('') < this.state.secretNumber) {
      newInstruction.unshift("The Number is greater than " + this.state.number.join(''));
    this.setState({
      instructions: newInstruction,
      number: [],
      count: this.state.count - 1,
      keycode: window.event.keyCode
    });
    }
    if(this.state.number.join('') == this.state.secretNumber) {
      newInstruction.unshift(<span>Congratulations !! </span>);
    this.setState({
      instructions: newInstruction,
      number: [],
      status: 'won',
      keycode: window.event.keyCode,
      defaultText: this.state.secretNumber,
      level: this.state.level + 1,
      score: this.state.score + this.state.count * this.state.level * 10
    });
      }
    }
  }

  newGame = () => {
    if(this.state.level === 1 || this.state.status === '' ) {
    this.setState({
    number: [],
    defaultText: '* *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 99) + 1,
    count: 8,
    status: '',
    lastNumber: 100,
    level: 1,
    score: 0,
    userName: '',
    showToppers: false
    });
  }

  if(this.state.level === 2 && this.state.status === 'won') {
    this.setState({
    number: [],
    defaultText: '* * *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 999) + 1,
    count: 9,
    status: '',
    lastNumber: 1000
    });
  }

  if(this.state.level === 3 && this.state.status === 'won') {
    this.setState({
    number: [],
    defaultText: '* * * *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 9999) + 1,
    count: 10,
    status: '',
    lastNumber: 10000
    });
  }

  if(this.state.level === 4 && this.state.status === 'won') {
    this.setState({
    number: [],
    defaultText: '* * * * *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 99999) + 1,
    count: 11,
    status: '',
    lastNumber: 100000
    });
  }

  if(this.state.level === 5 && this.state.status === 'won') {
    this.setState({
    number: [],
    defaultText: '* * * * * *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 999999) + 1,
    count: 12,
    status: '',
    lastNumber: 1000000
    });
  }

  if(this.state.level === 6 && this.state.status === 'won') {
    this.setState({
    number: [],
    defaultText: '* * * * * * *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 9999999) + 1,
    count: 13,
    status: '',
    lastNumber: 10000000
    });
  }

  if(this.state.level === 7 && this.state.status === 'won') {
    this.setState({
    number: [],
    defaultText: '* * * * * * * *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 99999999) + 1,
    count: 14,
    status: '',
    lastNumber: 100000000
    });
  }

  if(this.state.level === 8 && this.state.status === 'won') {
    this.setState({
    number: [],
    defaultText: '* * * * * * * * *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 999999999) + 1,
    count: 15,
    status: '',
    lastNumber: 1000000000
    });
  }

  if(this.state.level === 9 && this.state.status === 'won') {
    this.setState({
    number: [],
    defaultText: '* * * * * * * * * *',
    instructions: [],
    secretNumber: Math.floor(Math.random() * 9999999999) + 1,
    count: 16,
    status: '',
    lastNumber: 10000000000
    });
  }
  }
 
 inputUserName = () => {
   this.setState({
     userName: this.state.defaultUser
   });
 }

 handleChange = (e) => {
   this.setState({
     defaultUser: e.target.value
   })
 }

 toppers = (e) => {
   e.preventDefault();
   this.setState({showToppers: true});

   const now = DateTime.now();
   const formattedDate = now.toFormat('dd-MM-yyyy HH:mm:ss');

   const toppers = {
     userName: this.state.userName,
     score: this.state.score,
     rank: this.state.rank,
     time: formattedDate,
   }
   axios.post("/guess.json", toppers)
   .then(response => console.log('working'))
   .then(this.getToppers)
   .catch(error => console.log(error));
 }

  render() {
    let number = this.state.number.map((num, i) => {
      return <span key={i} >{num}</span>
    })
   
    return(
      <div className="page-container">
        <div className="page-heading">
          <h3>Guess the Secret Number 
          <span className="level">Level {this.state.level}</span>
          </h3>
        </div>
        <div className="page-input">        
        <span className="chances">You have {this.state.count} chances</span>
        <p>The number is between 0 and {this.state.lastNumber}</p>
        <div className="input-box">{this.state.defaultText}
        { 
          (this.state.count === 0) ? 
          <span className="answer">Answer: {this.state.secretNumber} </span> : 
          number 
        }
        </div>
        </div>
        
        <div className="page-instructions">
        {
          this.state.instructions.map((inst, i) => {
            return <p key={i}>{inst}</p>
          })
        }
        </div>
        {
          (this.state.count === 0) ? 
        <div>
          <Result 
          Answer={this.state.secretNumber} 
          newgame = {this.newGame} 
          score={this.state.score} 
          rank={this.state.rank} 
          count={this.state.count}  
          user = {this.state.userName} 
          toppers = {this.toppers} 
          showToppers={this.state.showToppers} 
          toprankers = {this.state.toprankers} 
          test = {this.test}
           />
        </div>  :  (this.state.status === 'won') ? 
        <NextLevel 
         Answer={this.state.secretNumber} 
         level={this.state.level} 
         score={this.state.score} 
         count={this.state.count} 
         newgame = {this.newGame} 
         /> :    (this.state.userName === '') ? 
          <div className="user-name">
          <input type="text" placeholder="Enter Your Name" onChange={this.handleChange} />
          <button onClick={this.inputUserName}> Start</button>
          </div> :       
        <div className="page-buttons">
          <button id="1" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '49') ? '2px solid #f50': null}} >1</button>
          <button id="2" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '50') ? '2px solid #f50': null}}>2</button>
          <button id="3" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '51') ? '2px solid #f50': null}}>3</button>
          <button id="4" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '52') ? '2px solid #f50': null}}>4</button>
          <button id="5" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '53') ? '2px solid #f50': null}}>5</button>
          <button id="6" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '54') ? '2px solid #f50': null}}>6</button>
          <button id="7" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '55') ? '2px solid #f50': null}}>7</button>
          <button id="8" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '56') ? '2px solid #f50': null}}>8</button>
          <button id="9" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '57') ? '2px solid #f50': null}}>9</button>
          <button onClick={this.deleteNumber} className="delete btn" onKeyDown={this.keyDown} style={{border: (this.state.keycode === '46') ? '2px solid #f50': null}} >DEL</button>
          <button onClick={this.submitAnswer} className="enter btn" onKeyDown={this.keyDown} style={{border: (this.state.keycode ==='13') ? '2px solid #f50': null}}>ENTER</button>
          <button id="0" className="btn" onClick={this.handleClick} onKeyDown={this.keyDown} style={{border: (this.state.keycode === '48') ? '2px solid #f50': null}}>0</button>
        </div> 
        }
      </div>
    );
  }
}
export default GuessLevelOne;