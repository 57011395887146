import React, { useEffect, useState } from 'react';
import './CharCounter.css'

const CharCounter = () => {

    const [charCount, setCharCount] = useState(0);
    const [wordCount, setWordCount] = useState(0);
    const [spaceCount, setSpaceCount] = useState(0);
    const [enteredChar, setEnteredChar] = useState(null);
    // const [enteredCharCount, setEnteredCharCount] = useState(0);
    const [enteredContent, setEnteredContent] = useState(null);
    const [caseSensitiveCount, setCaseSensitiveCount] = useState(0);
    const [caseInSensitiveCount, setCaseInSensitiveCount] = useState(0);

    let caseSensitiveCountResult = enteredChar?.length > 0 ? (<>Total matches : <b>{caseSensitiveCount}</b> -- Case sensitive(Exact Match)</>): '';
    let caseInSensitiveCountResult = enteredChar?.length > 0 ? (<>Total matches : <b>{caseInSensitiveCount}</b> -- Case insensitive(Includes small / capital Letters)</>): '';

    const handleChange = (event) => {
        const value = event.target.value;
        const charCountLength = value.length;
        const wordCountLength = value.trim() === "" ? 0 : value.trim().split(/\s+/).length;
        const spacesLength = value.split(" ").length - 1;
        setCharCount(charCountLength);
        setWordCount(wordCountLength);
        setSpaceCount(spacesLength);
        setEnteredContent(value)
    }

    const checkCount = (event) => {
        const value = event.target.value;
        setEnteredChar(value);
        const caseSensitiveCount = enteredContent.split(value).length - 1;
        setCaseSensitiveCount(caseSensitiveCount);
        const caseInsensitiveCount = enteredContent.toLowerCase().split(value.toLowerCase()).length - 1;
        setCaseInSensitiveCount(caseInsensitiveCount);
    }

    return <div className='char-counter-container'>
        <h2 className='char-counter-header'>Character Counter</h2>
        <textarea className='char-counter-textarea' type='text' placeholder='Enter your content here ...' onChange={(event) => handleChange(event)} />
        <div className='default-char-counter-results'>
            <span className='default-char-counter-result'>Characters: {charCount}</span>
            <span className='default-char-counter-result'>Words: {wordCount}</span>
            <span className='default-char-counter-result'>Spaces: {spaceCount}</span>
        </div>
        <div className='char-counter-finder-wrapper'>
            <textarea className='char-counter-finder' type='text' placeholder='Type anything here to know the matches ...' onChange={(event) => checkCount(event)} />
        </div>
        <div className='char-counter-result-wrapper'>
            <p className='char-counter-result'>{caseSensitiveCountResult}</p>
            <p className='char-counter-result'>{caseInSensitiveCountResult}</p>
        </div>
    </div>
}

export default CharCounter