import React from 'react';
import axios from 'axios';
import './SnakeBoard.css';
import Snake from './Snake';
import Food from './Food';
import User from './User';
import Result from './Result';
import DocumentMeta from 'react-document-meta';
import { DateTime } from 'luxon'
import '../Navigation/Games.css';

const getRandomCoordinates = () => {
  let min = 1;
  let max = 97;
  let x = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2;
  let y = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2;
  return[x,y];
}

const meta = {
    title: 'Snake Game',
    description: 'Snake Game',
    canonical: 'https://timepassgame.com/snake-game',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'snakegame, timepassgame, snake, timepass, games'
        }
    }
}

class SnakeBoard extends React.Component {
  state = {
    snakeDots: [[0, 0], [2, 0]],
    food: getRandomCoordinates(),
    direction: "RIGHT",
    speed: 500,
    userName: '',
    defaultUser: '',
    level: 'Easy',
    showToppers: false,
    toprankers: []
  }

  start = '';

  componentDidMount = () => {
    //setInterval(this.moveSnake, this.state.speed);
    document.onkeydown = this.onKeyDown;
  }
  componentDidUpdate = () => {
    this.checkBorders();
    this.checkIfCollapsed();
    this.checkIfEat();    
  }
  
  componentWillUnmount = () => {
    clearInterval(this.start);
  }

  initialState = () => {
    clearInterval();
    this.setState({
    snakeDots: [[0, 0], [2, 0]],
    food: getRandomCoordinates(),
    direction: "RIGHT",
    speed: 500,
    userName: '',
    defaultUser: '',
    level: 'Easy',
    showToppers: true
    });
}

  onKeyDown = e => {
    e = e || window.event;
    switch(e.keyCode) {
      case 37:
        this.setState({ direction: "LEFT" });
        break;
      case 38: 
        this.setState({ direction: "UP" });
        break;
      case 39:
        this.setState({ direction: "RIGHT" });
        break;
      case 40:
        this.setState({ direction: "DOWN" });
        break;
    }
  }
  handleClick =(e) => {
    this.setState({
      direction: e.target.id
    });
  }
  moveSnake = () => {
    let dots = [...this.state.snakeDots];
    let head = dots[dots.length - 1];

    switch (this.state.direction) {
      case "LEFT": 
        head = [head[0] - 2, head[1]];
        break;
      case "UP": 
        head = [head[0], head[1] - 2];
        break;
      case "RIGHT": 
        head = [head[0] + 2, head[1]];
        break;
      case "DOWN": 
        head = [head[0], head[1] + 2];
        break;
    }
    dots.push(head);
    dots.shift();
    this.setState({
      snakeDots: dots
    });
  }
  checkBorders = () => {
    let head = this.state.snakeDots[this.state.snakeDots.length - 1];
    if(head[0] >= 100 || head[1] >= 100 || head[0] < 0 || head[1] < 0 ) {
      this.onGameOver();
    }
  }
  onGameOver = () => {
    //alert(`Game Over !! Snake length is ${this.state.snakeDots.length - 2}`);
    this.toppers();
    clearInterval(this.start);
    this.initialState();
  }
  newGame = () => {
    this.setState({
    snakeDots: [[0, 0], [2, 0]],
    food: getRandomCoordinates(),
    direction: "RIGHT",
    speed: 500,
    userName: '',
    defaultUser: '',
    level: 'Easy',
    showToppers: false,
    toprankers: []
    });
  }
  toppers = () => {   
     this.setState({showToppers: true});

     const now = DateTime.now();
     const formattedDate = now.toFormat('dd-MM-yyyy HH:mm:ss');

   const toppers = {
     userName: this.state.userName,
     score: this.state.snakeDots.length - 2,
     level: this.state.level,
     time: formattedDate
   }
   axios.post("/snake.json", toppers)
   .then(response => console.log('working'))
   .then(this.getToppers)
   .catch(error => console.log(error));
 }
 getToppers = () => {
   axios.get("/snake.json")
   .then(response => {
     const fetchedResults = [];
     for(let key in response.data){
       fetchedResults.unshift(
         {
           ...response.data[key],
           id: key
         }
       )
     }
     this.setState({
       toprankers: fetchedResults.sort((a,b) => (b.score - a.score))
     });
   })
 }
  checkIfCollapsed = () => {
    let snake = [...this.state.snakeDots];
    let head = snake[snake.length - 1];
    snake.pop();
    snake.forEach(dot => {
      if(head[0] == dot[0] && head[1] == dot[1]) {
        this.onGameOver();
      }
    })
  }
  checkIfEat = () => {
    let head = this.state.snakeDots[this.state.snakeDots.length - 1];
    let food = this.state.food;
    if(head[0] == food[0] && head[1] == food[1]) {
      this.setState({
        food: getRandomCoordinates()
      })
        this.enlargeSnake();
    }
  }
  enlargeSnake = () => {
      let newSnake = [...this.state.snakeDots];
      newSnake.unshift([]);
      this.setState({
        snakeDots: newSnake,
        speed: this.state.speed - 10
      });
  }

  handleChange = (event) => {
    this.setState({
      defaultUser: event.target.value
    });
  }
  changeLevel = (event) => {
    this.setState({
      level: event.target.value
    })
  }
  handleSubmit = () => {
    this.setState({
      userName: this.state.defaultUser
    });
    if(this.state.defaultUser !== '') {
      if(this.state.level === 'Easy') {
      this.start = setInterval(this.moveSnake, 500);
      }
      if(this.state.level === 'Medium') {
      this.start = setInterval(this.moveSnake, 250);
      }
      if(this.state.level === 'Expert') {
      this.start = setInterval(this.moveSnake, 50);
      }
    }
  }
  
  render() {
    return(
      <div className="Games-page">
      <DocumentMeta {...meta} />
      <div className="side-bar"></div>
      <div className="Game-Container">
      <div className="snake-board">
        <Snake snakeDots={this.state.snakeDots} />
        <Food dot={this.state.food} />
      </div>
      <p className="snake-score-label">SCORE</p>
      <p className="snake-score">{this.state.snakeDots.length - 2}</p>
      <p className="snake-level-label">LEVEL</p>
      <p className="snake-level">{this.state.level}</p>
      <button onClick={this.handleClick} className="snake-left-button" id="LEFT">L</button>
      <button onClick={this.handleClick} className="snake-right-button" id="RIGHT">R</button>
      <button onClick={this.handleClick} className="snake-up-button" id="UP">U</button>
      <button onClick={this.handleClick} className="snake-down-button" id="DOWN">D</button>
      {
        (this.state.userName === '') ? 
        <User handleChange = {this.handleChange} 
         handleSubmit = {this.handleSubmit} 
         changeLevel = {this.changeLevel} /> : null
      }
      {
        (this.state.showToppers === true) ? 
        <Result newgame={this.newGame} 
        toprankers={this.state.toprankers} 
        getToppers={this.getToppers} 
        showToppers={this.state.showToppers} /> 
        : null
      }
      </div>      
      <div className="side-bar"></div>
    </div>
    );
  }
}
export default SnakeBoard;